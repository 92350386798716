import { Dialog } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"

function ParticipantDetailsModal({ open, handleClose, data }) {
	const columns = [
		{
			field: "name",
			headerName: "Name",
			width: 250,
		},
		{
			field: "email",
			headerName: "Email",
			width: 250,
		},
		{
			field: "year",
			headerName: "Year",
			width: 250,
		},
		{
			field: "branch",
			headerName: "Branch",
			width: 250,
		},
		{
			field: "roll",
			headerName: "Roll",
			width: 250,
		},
		{
			field: "phoneNumber",
			headerName: "Phone Number",
			width: 250,
		},
		{
			field: "whatsappNumber",
			headerName: "Whatsapp Number",
			width: 250,
		},
	]
	return (
		<Dialog open={open} onClose={handleClose} maxWidth='xl' fullWidth>
			<div className='participant-details-modal-grid'>
				{console.log(data)}
				{data && Array.isArray(data) && (
					<DataGrid
						rows={data}
						disableSelectionOnClickx
						disableSelectionOnClick
						columns={columns}
						getRowId={(row) => row._id}
						style={{ fontSize: "0.9rem" }}
						pagination
					/>
				)}
			</div>
		</Dialog>
	)
}

export default ParticipantDetailsModal
