import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Logo from "../../assets/elabs_logo.png"
import { ExitToApp } from "@mui/icons-material"
import "./Navbar.css"

function Navbar() {
	// const location = useLocation();
	// const [path, setPath] = useState();
	// useEffect(() => {
	//     setPath(location.pathname);
	// }, [location]);

	const [user, setUser] = useState(JSON.parse(localStorage.getItem("ud")))
	const [isAuth, setIsAuth] = useState(false)
	useEffect(() => {
		if (user) {
			setIsAuth(true)
		} else {
			setIsAuth(false)
		}
	}, [user])

	const handleLogout = () => {
		localStorage.removeItem("ud")
		setUser(null)
		setIsAuth(false)
	}

	return (
		<>
			<div className='topbar'>
				<div className='topbarWrapper'>
					<h1 className={"topLeft"}>
						<Link to='/' className='link'>
							<img src={Logo} height='42' alt='nothing' />E Labs
						</Link>
					</h1>
					<div className='topRight'>
						{isAuth && (
							<>
								<Link className='link' to='/myProfile'>
									<div className='top-details'>
										<div>
											<img className='topAvatar' src={user.uphoto} alt='' />
										</div>
										<div className='top-user'>
											<div className='top-name'>{user.uname}</div>
											<div className='top-domain'>{user.udomain}</div>
										</div>
									</div>
								</Link>
								<div onClick={() => handleLogout()} title='Logout'>
									<ExitToApp
										className='top-logout'
										style={{
											width: "1.8rem",
											height: "1.8rem",
										}}
									/>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default Navbar
