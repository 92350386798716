import "./App.css"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Login from "./pages/Login/Login.jsx"
import Admin from "./pages/Admin/Admin.jsx"
import Navbar from "./components/Navbar/Navbar.jsx"
import Home from "./pages/home/Home"

function App() {
	//const isAuth = localStorage.getItem("ud") ? true : false;

	return (
		<>
			<Router>
				<Navbar />
				<Routes>
					{/* {isAuth ? (
						<Route path='/dashboard' element={<Admin />} />
					) : (
						<Route path='/' element={<Login />} />
					)} */}
					<Route path='/' element={<Home />} />
				</Routes>
			</Router>
		</>
	)
}

export default App
