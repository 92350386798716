import React, { useState, useEffect } from "react"
import API from "../../components/API"
import AlertBox, {
	AlertBoxTypes,
	changeAlertBoxState,
} from "../../components/AlertBox/AlertBox"
import "./home.css"
import { DataGrid } from "@mui/x-data-grid"
import ParticipantDetailsModal from "../../components/ParticipantDetails/ParticipantDetailsModal"

const calculateParticipantCount = (teamList) => {
	var participantCount = 0
	for (let eachTeam of teamList) {
		participantCount += eachTeam.members.length
	}
	console.log(participantCount)
	return participantCount
}

function Home() {
	const [teams, setTeams] = useState([])
	const [teamCount, setTeamCount] = useState(0)
	const [participantCount, setParticipantCount] = useState(0)
	const [participantDetailsModal, setParticipantDetailsModal] = useState(false)
	const [eachTeamParticipantDet, setEachTeamParticipantDet] = useState([])

	const [alertBoxState, setAlertBoxState] = useState({
		open: false,
		type: AlertBoxTypes.SUCCESS,
		message: "",
		title: null,
	})

	const openParticipantModal = () => {
		setParticipantDetailsModal(true)
	}

	const columns = [
		{
			field: "teamName",
			headerName: "Team Name",
			width: 300,
			renderCell: (param) => {
				return (
					<span
						className='team-name-click'
						onClick={() => {
							setEachTeamParticipantDet(param.row.members)
							openParticipantModal()
						}}
					>
						{param.row.teamName}
					</span>
				)
			},
		},
		{
			field: "token",
			headerName: "Token",
			width: 250,
		},
		{
			field: "participantCount",
			headerName: "Participant Count",
			width: 300,
			renderCell: (params) => {
				return params.row.members.length
			},
		},
		{
			field: "crossword",
			headerName: "Crossword",
			width: 300,
			renderCell: (params) => {
				return <span>Details</span>
			},
		},
		{
			field: "quiz",
			headerName: "Quiz",
			width: 300,
			renderCell: (params) => {
				return <span>Details</span>
			},
		},
		{
			field: "treasureHunt",
			headerName: "Treasure Hunt",
			width: 300,
			renderCell: (params) => {
				return <span>Details</span>
			},
		},
	]

	useEffect(() => {
		const fetchTeam = async () => {
			const result = await API("/team")
			console.log(result)
			if (result.status === 200) {
				setTeams(result.data.teams)
				setTeamCount(result.data.teams.length)
				setParticipantCount(calculateParticipantCount(result.data.teams))
			} else {
				changeAlertBoxState(
					AlertBoxTypes.ERROR,
					"Fetching error",
					"There was problem in fetching the member details",
					setAlertBoxState
				)
			}
		}
		fetchTeam()
	}, [])

	return (
		<>
			<div className='main-app'>
				<div className='count-panel'>
					<div className='team-count-panel'>
						<div className='team-count'>{teamCount}</div>
						<div className='team-text'>TEAMS</div>
					</div>
					<div className='participants-count-panel'>
						<div className='team-count'>{participantCount}</div>
						<div className='team-text'>PARTICIPANTS</div>
					</div>
				</div>
				<div className='grid-panel'>
					<div className='grid-panel-topbar'>
						<div className='grid-header'>Team Details</div>
					</div>
					<DataGrid
						rows={teams}
						disableSelectionOnClickx
						disableSelectionOnClick
						columns={columns}
						getRowId={(row) => row._id}
						loading={teams.length === 0}
						style={{ fontSize: "0.9rem" }}
						pagination
					/>
				</div>
			</div>
			<AlertBox
				type={alertBoxState.type}
				title={alertBoxState.title}
				message={alertBoxState.message}
				state={alertBoxState.open}
				handleState={setAlertBoxState}
			/>
			<ParticipantDetailsModal
				open={participantDetailsModal}
				handleClose={() => {
					setParticipantDetailsModal(false)
				}}
				data={eachTeamParticipantDet}
			/>
		</>
	)
}

export default Home
